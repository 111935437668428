import AU from './AU'
import JP from './JP'
import EU from './EU'
import CA from './CA'
import KR from './KR'
import NZ from './NZ'
import RU from './RU'
import UE from './UE'
import US from './US'

export default {
  US,
  EU,
  CA,
  AU,
  JP,
  NZ,
  KR,
  UE,
  RU
}
