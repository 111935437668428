import EN from './EN'
import EN_MJ from './EN/MJ'
import EN_VEG from './EN/VEG'
import FR from './FR'
import FR_MJ from './FR/MJ'
import FR_VEG from './FR/VEG'
import NL from './NL'
import NL_MJ from './NL/MJ'
import NL_VEG from './NL/VEG'
import PL from './PL'
import PL_MJ from './PL/MJ'
import PL_VEG from './PL/VEG'
import RU from './RU'
import RU_MJ from './RU/MJ'
import RU_VEG from './RU/VEG'

export default {
  EN,
  EN_MJ,
  EN_VEG,
  FR,
  FR_MJ,
  FR_VEG,
  NL,
  NL_MJ,
  NL_VEG,
  PL,
  PL_MJ,
  PL_VEG,
  RU,
  RU_MJ,
  RU_VEG,
}
